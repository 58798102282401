/* .action-button button{
    border: 2px solid #707070;
    margin-left: 20px;
    background: #fff;
    padding: 5px 20px;
    cursor: pointer;
}

.action-button{
    margin-top: 20px;
    text-align: right;
} */

.action-button a {
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    .steps{
      display: flex;
      flex-direction: column-reverse;
     }
     .action-button > [class*="col-"] {
        margin-bottom: 10px;
     }
  }