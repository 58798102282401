.markdown {
    table {
      border-collapse: collapse;
    }
  
    th,
    td {
      padding: 6px 13px;
      border: 1px solid black;
    }
  
    p {
      line-height: 1.5;
    }
  }/* Add this CSS to your main stylesheet, e.g., App.css */

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

table, th, td {
  border: 1px solid black; /* This adds the lines (borders) */
}

th, td {
  padding: 3px;
}

th {
  background-color: #f2f2f2;
}

  