@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
:root {
  --current-color: #000000;
  --ycm-orange: #FF944C;
  --current-font: 'Montserrat', sans-serif;
  --gray-bg: rgba(99, 136, 198, 0.05);;
  --light-gray-bg: #F7F7F7;
  --extra-light-gray-bg: #F3F4F4;
  --gray-color: #171717;
  --border-color: #dddddd;
  --theme-color-1: #000000;
  --theme-color-2: #198754;
  --theme-color-3: #008F8F;
  --pink-color: #FEF3EF;
}
html, body {
  overflow-x: hidden;
}