#root{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
* {
  outline: 0;
  font-family: var(--current-font) !important;
}
.main{
  flex: 1;
}
/*Font Styles*/
a {
  text-decoration: none !important;
}

h1.title {
  font-weight: 600;
  font-size: 42px;
  line-height: 56px;
  margin-bottom: 15px;
}

h2.title.text-white {
  color: white;
}
.ycm-orange{
  color: var(--ycm-orange);
}
h2.title {
  font-weight: 600;
  color: var(--theme-color-3);
  font-size: 20px;
}
h3{
  color: var(--current-color);
}
h3.title {
  font-weight: 600;
  font-size: 15px;
}

h4.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  font-weight: bold;
  text-align: left !important;
}
.box-message {
  border: 0 solid #707070;
  font: var(--current-font);
  letter-spacing: 0;
  padding: 20px;
  text-align: left;
}
.terms{
  font-size: 10px;
}
.p{
  font-size: 16px;
}
.tips{
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  body {
    font-size: 14px !important;
  }
  .box-message {
    font-size: 15px;
  }
  .p{
    font-size: 14px;
  }
}
/*Font Styles End*/
/*Image Styles*/
.site-logo img{
  height: 100px;
}
.light-bulb{
  height: 27px;
  margin-right: 4px;
}
.heading-icon img, .icons{
  height: 80px;
  margin-right: 4px;
}
.review-popup img{
  height: 80px;
  padding: 0.5rem;
}
.review-popup button{
  max-width: 100px;
  margin: 0.5rem;
  border: unset;
  background-color: #ffffff;
}
@media screen and (max-width: 768px) {
  .site-logo img{
    height: 60px;
  }
  .review-popup img{
    height: 60px;
  }
  .review-popup button{
    max-width: 80px;
  }
}
/*Image Styles End*/
/*Background Styling*/
.fdc-form-wrapp {
  width: 70%;
  margin: 0 auto;
}

.fdc-shadow-block {
  -moz-box-shadow: 0 4px 27px 0px rgb(0 0 0 / 3%);
  -webkit-box-shadow: 0 4px 27px 0px rgb(0 0 0 / 3%);
  box-shadow: 0 4px 27px 0px rgb(0 0 0 / 3%);
}
.fdc-title-block {
  background-color: var(--light-gray-bg);
  padding: 20px;
}

.fdc-form {
  background-color: #ffffff;
  padding: 20px 50px;
}

.form-block label {
  font-weight: 600;
}
.full-width .form-label{
  width: 100%;
}
.form-block {
  position: relative;
  margin-bottom: 30px;
}
.lender-block{
    align-items: center; /* Align items vertically */
    margin-bottom: 10px;
    padding: 10px; /* Adjust padding as needed */
    background-color: #cbf2f9;
    border-radius: 7px;
    position: relative;
}
@media screen and (max-width: 991px) {
 
  .fdc-form-wrapp {
    width: 100%;
  }

  .fdc-form {
      padding: 15px 15px;
  }
}
@media screen and (max-width: 768px) {
  .form-block label {
      font-weight: 600;
      font-size: 14px;
  }
}
/*Background Styling End*/
/*Input Styling*/
.form-block input,
.form-block textarea {
    padding: 10px 20px;
    border: 1px solid var(--theme-color-1);
    border-radius: 0.375rem;
    color: var(--gray-color);
    width: 100%;
    font-weight: 500;
    background-color: transparent;
}
.form-block .PhoneInputInput{
    border: none;    
    border-radius: 0.375rem;
}
.form-block input[type=text]{
  font-weight: bolder;
}
.form-block select {
    padding: 10px 10px;
    border: 1px solid var(--theme-color-1);
    border-radius: .375rem;
    color: var(--gray-color);
    width: 100%;
    font-weight: bolder;
    background-color: transparent;
}
.form-check-input {
    margin: 0 !important;
}
textarea {
    min-height: 100px;
}
.btn-w {
  text-align: center;
  padding: 12px 30px;
  text-align: center;
  font-weight: 600;
  display: inline-block;
  min-width: 130px;
  transition: .3s;
  border-radius: 5px;
}

.btn-w:hover {
  animation: pulse 1s;
}
.remove-animation button{
  animation: none !important;
}
.t-carousel .btn-w.sm:hover {
  animation: none;
}

.btn-w.sm {
  font-size: 12px;
}

.btn-w.text-start {
  padding-left: 15px;
}

.btn-w.color1 {
  background-color: var(--theme-color-1);
  color: #ffffff;
}

.btn-w.color2 {
  background-color: var(--theme-color-2);
  color: #ffffff;
}

.btn-w.color3 {
  background-color: var(--theme-color-3);
  color: #ffffff;
}

.action-button .btn-w.color {
  background-color: var(--ycm-orange);
  color: var(--current-color);
}

.action-button .btn-w.color3 {
  background-color: var(--theme-color-3);
  color: var(--current-color);
}

.btn-w.black {
  background-color: var(--theme-color-1);
  color: #ffffff;
}

.btn-w.gray {
  background-color: var(--gray-bg);
  color: var(--current-color);
}
.t-and-c-check .form-check {
  display: flex;
}
.sign-up-terms{
  background: none;
  border: none;
  color: blue;
  padding: 0;
  display: contents;
}

@keyframes bouncearrow {
  50% {
      transform: translateY(-15px);
  }
}

@keyframes pulse {
  0% {
      transform: scale(1);
  }

  70% {
      transform: scale(.9);
  }

  100% {
      transform: scale(1);
  }
}
@media screen and (max-width:991px) {
  .t-and-c-check .form-check input {
    margin-top: 2px;
  }
}
@media screen and (max-width:768px) {
  .t-and-c-check{
    font-size: 12px;
  }
}

/*Input Styling End*/
/*Loading Spinner Styles*/
@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

.loader {
  display: none;
  position: absolute;
  border: 12px solid #ababab5c;
  border-top: 12px solid var(--ycm-orange);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  z-index: 4;
  animation: spin 2s linear infinite;
  top: 35%;
  bottom: 35%;
  left: 45%;
  right: 45%;
}

.loading-overlay {
  display: none;
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 3;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.active-spinner {
  position: relative;
}

.active-spinner .loading-overlay {
  display: block;
}

.active-spinner .loader {
  display: block;
}
@media screen and (max-width: 640px) {
  .loader{
    left: 35%;
  }
}
/*Loading Spinner Styles End*/
/*Progress Bar Styles*/
.stepper-loader{
  height: 20px;
  border: solid 2px var(--theme-color-1);
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-bottom: 1rem;
  margin-top: 2rem;
}

.stepper-loader div {
  height: 100%;
  background-color: var(--theme-color-3);
  padding-left: 0 !important;
  border-radius: 10px;
}
/*Progress Bar Styles End*/

/*Signature Styles*/
.sig-canvas {
  width: 100%;
  height: 184px;
  background-color: #FBFBFB;
  border: 1px solid var(--current-color);
}

p.sig-more-info{
  color: var(--current-color);
  font-weight: unset; 
  font-size: 12px !important;
  line-height: 19px;
}
.popup-content{
  width: 50% !important;
  padding: 10px !important;
  border-radius: 5px;
  background-color: white;
}
.undo-counter-overlay .popup-content{
  width: 50% !important;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .undo-counter-overlay .popup-content{
      width: 75% !important;
  }
  .popup-content{
    width: 75% !important;
  }
}
.popup-overlay{
  z-index: 3000 !important;
}
.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label {
  position: relative;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: start;
}

.checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid var(--gray-color);
  background-color: var(--gray-bg);
  box-shadow: none;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.checkbox input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 4px;
  width: 14px;
  height: 14px;
  background-color: var(--current-color);
}
/*Signature Styles End*/