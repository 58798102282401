.custom-button button {
    /* border: 2px solid #707070; */
    /* margin-left: 20px; */
    background-color: var(--theme-color-2);
    padding: 5px 20px;
    cursor: pointer;
    border: none;
}

.custom-button button:hover{
    background-color: var(--theme-color-2);
}

.custom-button {
    margin-top: 20px;
    /* text-align: right; */
}