.list-radio-options button {
    background: #fff;
    width: 40px;
    height: 40px;
    margin: 5px 10px;
    border: 1px solid #707070;
    cursor: pointer;
}

.list-radio-options button.selected {
    background: #D0D0D0 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
}

.list-radio-options {
    margin-top: 20px;
}

.list-radio-options .form-check label {
    margin-left: 5px;
    font-weight: 400;
}

.list-radio-options .form-check {
    margin-bottom: 10px;
}

.list-radio-options .form-check .form-check-input[type=checkbox] {
    width: auto;
    padding: 10px;
    border: 1px solid rgba(0,0,0,.25);
    border-radius: 50%;
}
.radio-button{
	border: 1px solid var(--theme-color-1);
	border-radius: 7px;
	padding: 10px !important;
}
.list-radio-options .form-check.no-radius .form-check-input[type=checkbox]{
    border-radius: 0 !important;
} 